var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"module-edit-modal","title":"Module Edit Modal","hide-footer":"","size":"lg","no-close-on-esc":true,"no-close-on-backdrop":true,"centered":""},on:{"hidden":_vm.resetModal},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("Edit Module")])]},proxy:true}])},[_c('validation-observer',{ref:"moduleEditFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Name","disabled":!_vm.allowEdit},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"placeholder":"Title","disabled":!_vm.allowEdit},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"Description","disabled":!_vm.allowEdit},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Route","label-for":"route"}},[_c('validation-provider',{attrs:{"name":"Route","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"route","state":errors.length > 0 ? false : null,"placeholder":"Route","disabled":!_vm.allowEdit},model:{value:(_vm.route),callback:function ($$v) {_vm.route=$$v},expression:"route"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"image"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image ")]},proxy:true}])},[_c('b-form-file',{ref:"image",staticClass:"mt-0",attrs:{"state":Boolean(_vm.image),"accept":_vm.acceptedFiles.join(', '),"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","disabled":!_vm.allowEdit},on:{"change":function($event){return _vm.uploadImage($event)}},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1)],1)],1),(_vm.image)?[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"sm":"12","md":"12","lg":"12","xl":"12"}},[_c('b-img',{attrs:{"src":_vm.imageURL,"rounded":"","fluid":""}})],1)],1)]:_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"roles","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"roles","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Roles "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"v-style-chooser",attrs:{"id":"roles","inputId":"id","label":"name","options":_vm.roles,"placeholder":"Roles","multiple":"","disabled":!_vm.allowEdit},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(_vm.allowEdit)?_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"size":"md","variant":"primary"},on:{"click":_vm.selectAllRoles}},[_vm._v(" Select All ")])],1):_vm._e(),(_vm.allowEdit)?_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"size":"md","variant":"danger"},on:{"click":_vm.unSelectAllRoles}},[_vm._v(" Unselect All ")])],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":"userTypes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"userTypes","state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" User Types "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"v-style-chooser",attrs:{"id":"userTypes","inputId":"id","label":"name","options":_vm.userTypesArr,"placeholder":"User Types","multiple":"","disabled":!_vm.allowEdit},model:{value:(_vm.selectedUserTypes),callback:function ($$v) {_vm.selectedUserTypes=$$v},expression:"selectedUserTypes"}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),(_vm.allowEdit)?_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"size":"md","variant":"primary"},on:{"click":_vm.selectAllUserTypes}},[_vm._v(" Select All ")])],1):_vm._e(),(_vm.allowEdit)?_c('b-col',{attrs:{"cols":"2"}},[_c('b-button',{staticClass:"mt-2",attrs:{"size":"md","variant":"danger"},on:{"click":_vm.unSelectAllUserTypes}},[_vm._v(" Unselect All ")])],1):_vm._e()],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"isUnavailable","name":"isUnavailable","disabled":!_vm.allowEdit},model:{value:(_vm.isUnavailable),callback:function ($$v) {_vm.isUnavailable=$$v},expression:"isUnavailable"}},[_c('h5',[_c('strong',[_vm._v(" Is Unavailable ")])])])],1)],1)],1),(_vm.isUnavailable)?_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"IsUnavailableMessage","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"IsUnavailableMessage","label-for":"isUnavailableMessage"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"isUnavailableMessage","state":errors.length > 0 ? false : null,"placeholder":"Please enter message to display to user if module is unavailable","disabled":!_vm.allowEdit},model:{value:(_vm.isUnavailableMessage),callback:function ($$v) {_vm.isUnavailableMessage=$$v},expression:"isUnavailableMessage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1847865338)})],1)],1):_vm._e(),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"blockField"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"BlockField","label-for":"blockField"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Block Field "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"blockField","state":errors.length > 0 ? false : null,"placeholder":"Block field name","disabled":!_vm.allowEdit},model:{value:(_vm.blockField),callback:function ($$v) {_vm.blockField=$$v},expression:"blockField"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"blockMessage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"BlockMessage","label-for":"blockMessage"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Block Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"blockMessage","state":errors.length > 0 ? false : null,"placeholder":"Block message","disabled":!_vm.allowEdit},model:{value:(_vm.blockMessage),callback:function ($$v) {_vm.blockMessage=$$v},expression:"blockMessage"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.allowEdit)?[_c('b-form-group',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-1 mt-2",attrs:{"type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Update ")])],1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }