<template>
    <b-modal id="module-edit-modal" title="Module Edit Modal" hide-footer size="lg"
      @hidden="resetModal" :no-close-on-esc="true" :no-close-on-backdrop="true" centered>
        <template #modal-title>
            <h2 class="m-0">Edit Module</h2>
        </template>
        <validation-observer ref="moduleEditFormValidation">
        <b-form @submit.prevent>
          <b-row>
                <b-col>
                    <b-form-group label="Name" label-for="name">
                    <validation-provider
                        #default="{ errors }"
                        name="Name"
                        rules="required"
                    >
                        <b-form-input
                        id="name"
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                        :disabled="!allowEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Title" label-for="title">
                    <validation-provider
                        #default="{ errors }"
                        name="Title"
                        rules="required"
                    >
                        <b-form-input
                        id="title"
                        v-model="title"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Title"
                        :disabled="!allowEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Description" label-for="description">
                    <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                    >
                        <b-form-input
                        id="description"
                        v-model="description"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Description"
                        :disabled="!allowEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group label="Route" label-for="route">
                    <validation-provider
                        #default="{ errors }"
                        name="Route"
                        rules="required"
                    >
                        <b-form-input
                        id="route"
                        v-model="route"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Route"
                        :disabled="!allowEdit"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <b-form-group label-for="image">
                    <template #label> Image </template>
                    <b-form-file v-model="image" class="mt-0"
                      :state="Boolean(image)" :accept="acceptedFiles.join(', ')"
                      @change="uploadImage($event)" placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..." ref="image" :disabled="!allowEdit"/>
                  </b-form-group>
              </b-col>
            </b-row>
            <template v-if="image">
                <b-row class="mb-1">
                    <b-col sm="12" md="12" lg="12" xl="12">
                        <b-img :src="imageURL" rounded fluid> </b-img>
                    </b-col>
                </b-row>
            </template>            
            <b-row>
              <b-col cols="8">
                <validation-provider #default="{ errors }" name="roles" rules="required">
                <b-form-group label-for="roles" :state="errors.length > 0 ? false : null">
                    <template #label>
                    Roles <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="roles" inputId="id" label="name" v-model="selectedRoles"
                            :options="roles" placeholder="Roles" class="v-style-chooser" multiple :disabled="!allowEdit"/>
                        </b-col>
                    </b-row>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2" v-if="allowEdit">
                <b-button size="md" class="mt-2" variant="primary" @click="selectAllRoles">
                  Select All
                </b-button>
              </b-col>
              <b-col cols="2" v-if="allowEdit">
                <b-button size="md" class="mt-2" variant="danger" @click="unSelectAllRoles">
                  Unselect All
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="8">
                <validation-provider #default="{ errors }" name="userTypes" rules="required">
                <b-form-group label-for="userTypes" :state="errors.length > 0 ? false : null">
                    <template #label>
                    User Types <span class="text-danger">*</span>
                    </template>
                    <b-row>
                        <b-col>
                            <v-select id="userTypes" inputId="id" label="name" v-model="selectedUserTypes"
                            :options="userTypesArr" placeholder="User Types" class="v-style-chooser" multiple :disabled="!allowEdit"/>
                        </b-col>
                    </b-row>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                    </b-form-invalid-feedback>
                </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2" v-if="allowEdit">
                <b-button size="md" class="mt-2" variant="primary" @click="selectAllUserTypes">
                  Select All
                </b-button>
              </b-col>
              <b-col cols="2" v-if="allowEdit">
                <b-button size="md" class="mt-2" variant="danger" @click="unSelectAllUserTypes">
                  Unselect All
                </b-button>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col>
                <b-form-group>
                  <!-- <template #label>
                    User Types <span class="text-danger">*</span>
                  </template>                  -->
                  <b-form-checkbox
                    id="isUnavailable"
                    v-model="isUnavailable"
                    name="isUnavailable"
                    :disabled="!allowEdit"
                  >
                    <h5>
                      <strong>
                        Is Unavailable
                      </strong>
                    </h5>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="isUnavailable">
                <b-col>
                  <validation-provider
                  #default="{ errors }"
                  name="IsUnavailableMessage"
                  rules="required"
                  >
                    <b-form-group label="IsUnavailableMessage" label-for="isUnavailableMessage">
                      <template #label>
                        Message <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                      id="isUnavailableMessage"
                      v-model="isUnavailableMessage"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Please enter message to display to user if module is unavailable"
                      :disabled="!allowEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <validation-provider
                  #default="{ errors }"
                  name="blockField"
                  >
                    <b-form-group label="BlockField" label-for="blockField">
                      <template #label>
                        Block Field <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                      id="blockField"
                      v-model="blockField"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Block field name"
                      :disabled="!allowEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
            </b-row>
            <b-row>
              <b-col>
                  <validation-provider
                  #default="{ errors }"
                  name="blockMessage"
                  >
                    <b-form-group label="BlockMessage" label-for="blockMessage">
                      <template #label>
                        Block Message <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                      id="blockMessage"
                      v-model="blockMessage"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Block message"
                      :disabled="!allowEdit"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
            </b-row>
            <template v-if="allowEdit">
              <b-form-group class="text-right">
                  <b-button type="submit" variant="primary" pill class="mr-1 mt-2" @click="validationForm">
                      Update
                  </b-button>
              </b-form-group>
            </template>
        </b-form>
       </validation-observer>
    </b-modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import util from "@/util.js";
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [util],
    props: ["showModal", "module", "allowEdit"],
    data() {
        return {
          name: "",
          title: "",
          description: "",
          route: "",
          image: null,
          imageURL: "",
          acceptedFiles: [".svg",".png",".jpg"],
          roles: [],
          selectedRoles: [],
          selectedUserTypes: [],
          isUnavailable: false,
          isUnavailableMessage: '',
          blockField: '',
          blockMessage: '',
        };
    },
    async mounted() {
      if (this.showModal) {
        try {
          if(this.module){
            const res = await this.getUnpaginatedRoles()
            this.roles = res.data

            this.name = this.module.name
            this.title = this.module.title
            this.description = this.module.description
            this.route = this.module.route
            this.isUnavailable  = this.module.is_unavailable
            this.isUnavailableMessage  = this.module.is_unavailable_message
            this.blockField = this.module.block_field
            this.blockMessage = this.module.block_message

            this.imageURL = this.module.image;
            if (this.module.image) {
              fetch(this.module.image)
                  .then((response) => response.blob())
                  .then((blob) => {
                  this.$refs.image.files.push(new File([blob], this.module.image.split('/').pop()))
                  this.image = this.$refs.image.files[0]
                  });
            }
            for(let i=0; i<this.roles.length; i++){
              if(this.module.roles.includes(this.roles[i].code_name)){
                this.selectedRoles.push(this.roles[i])
              }
            }            
            for(let i=0; i<this.userTypesArr.length; i++){
              if(this.module.user_types.includes(this.userTypesArr[i].id)){
                this.selectedUserTypes.push(this.userTypesArr[i])
              }
            }

          }
        } catch (error) {
        }
      }
    },
    methods: {
      ...mapActions({
        updateModule: "appData/updateModule",
        getUnpaginatedRoles: "appData/getUnpaginatedRoles",
      }),
      selectAllRoles(){
        this.selectedRoles = this.roles
      },
      unSelectAllRoles(){
        this.selectedRoles = []
      },
      selectAllUserTypes(){
        this.selectedUserTypes = this.userTypesArr
      },
      unSelectAllUserTypes(){
        this.selectedUserTypes = []
      },
      async uploadImage(event) {
        try {
            this.image = null;
            if (!event.target.files[0]) {
            return;
            }
            if(!(event.target.files[0].type.includes("image/svg")) && !(event.target.files[0].type.includes("image/png")) && !(event.target.files[0].type.includes("image/jpeg"))) {
            this.$refs.image.reset();
            // this.$swal({
            //     title: "Please upload an svg image",
            //     icon: "error",
            // });
            return;
            }
            this.image = event.target.files[0];
            this.imageURL = URL.createObjectURL(this.image)
        } catch (error) {
            this.displayError(error);
        }
      },
      async validationForm() {
        const success = await this.$refs.moduleEditFormValidation.validate();
        if (success) {
          await this.submit();
        }
      },
      async submit() {
        try {
          let formData = new FormData();
          let selectedRolesList = []
          let selectedUserTypesList = []
          for(let i=0; i<this.selectedRoles.length; i++){
            selectedRolesList.push(this.selectedRoles[i].id)
          }
          for(let i=0; i<this.selectedUserTypes.length; i++){
            selectedUserTypesList.push(this.selectedUserTypes[i].id)
          }
          let dataToInsert = {
            name: this.name,
            title: this.title,
            description: this.description,
            route: this.route,
            roles_id: selectedRolesList,
            is_unavailable: this.isUnavailable,
            is_unavailable_message: this.isUnavailableMessage,
            user_types: selectedUserTypesList,
            block_field: this.blockField,
            block_message: this.blockMessage,
            created_by: this.getLoggedInUser.id,
            updated_by: this.getLoggedInUser.id,
          };
          formData.append("data", JSON.stringify(dataToInsert));
          if (this.image)
          {
            formData.append("image", this.image)
          }
          const res = await this.updateModule({
            payload: formData,
            pk: this.module.id,
          });
          if (res.status === 200) {
            this.$swal({
              title: "Module updated successfully",
              icon: "success",
            });
            // await this.resetModal();
            this.$nextTick(() => {
              this.$bvModal.hide("module-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() { },
      resetModal() {
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getUser" }),
    },
    watch: {
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  .scroll-area-size {
    height: 45vh;
  }
  
  .scroll-area-size ul {
    height: 100%;
  }
  
  .variant-class {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  </style>